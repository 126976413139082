<template>
    <el-dialog
        title="修改密码"
        :visible.sync="passwordVisible"
        width="500px"
        center
        top="20vh"
        :modal="true"
        :close-on-click-modal="false"
    >
        <el-form
            ref="passwordForm"
            :model="passwordData"
            label-position="left"
            status-icon
            :rules="passwordRules"
            label-width="80px"
        >
            <el-form-item label="用户名">
                <b>{{ passwordData.displayName }}</b>
            </el-form-item>
            <label><span></span><input
                type="text"
                name="hidden1"
                style="width: 0; height: 0; border: none;"
            /></label>
            <label><span></span><input
                type="password"
                name="hidden2"
                style="width: 0; height: 0; border: none;"
            /></label>
            <el-form-item
                v-if="modifyType === 'personal'"
                label="验证码"
                required
            >
                <el-input
                    v-model="passwordData.validationCode"
                    clearable
                    placeholder="请输入短信验证码"
                    class="input-with-select"
                >
                    <el-button
                        slot="append"
                        :style="{
                            color:
                                countDownTitle === '获取验证码' ||
                                countDownTitle === '重发验证码'
                                    ? '#3595E1'
                                    : '#c0c4cc'
                        }"
                        @click="sendValidation"
                    >
                        {{ countDownTitle }}
                    </el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="输入密码" prop="password" required>
                <el-input
                    v-model="passwordData.password"
                    show-password
                    clearable
                    type="password"
                    :maxlength="20"
                    placeholder="请输入密码"
                ></el-input>
                <div class="text-tips">
                    （8-20位，含字母、数字、特殊符号至少两种）
                </div>
                <div slot="error" slot-scope="scope" class="error-tips">
                    {{ scope.error }}
                </div>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword" required>
                <el-input
                    v-model="passwordData.confirmPassword"
                    show-password
                    clearable
                    type="password"
                    placeholder="请确认密码"
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button type="primary" @click="passwordForm()">确 定</el-button>
            <el-button @click="passwordVisible = false">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import axios from 'axios'

let that
export default {
    // TODO 密码修改
    props: {
        modifyType: {
            type: String,
            default: 'personal'
        }
    },
    data () {
        return {
            passwordVisible: false,
            passwordData: {
                employeeId: null,
                userId: null,
                displayName: null,
                password: null,
                confirmPassword: null,
                validationCode: null
            },
            passwordRules: {
                password: [
                    {
                        validator (rule, value, callback) {
                            if (!value) {
                                callback(new Error('请输入密码'))
                            } else {
                                const passwordRule = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/
                                if (!passwordRule.test(value)) {
                                    callback(new Error('密码格式不正确（8-20位，含字母、数字、特殊符号至少两种）'))
                                } else {
                                    callback()
                                }
                            }
                        }
                    }
                ],
                confirmPassword: [
                    {
                        validator (rule, value, callback) {
                            if (!value) {
                                callback(new Error('请确认密码'))
                            } else if (value !== that.passwordData.password) {
                                callback(new Error('两次输入密码不一致'))
                            } else {
                                callback()
                            }
                        }
                    }
                ]
            },
            countDownTitle: '获取验证码',
            countDownNumber0: 0,
            countDownNumber: 0
        }
    },
    created () {
        that = this
    },
    methods: {
        show (item) {
            console.log(item)
            let theForm = this.$refs['passwordForm']
            if (theForm) {
                theForm.resetFields()
            }
            this.passwordVisible = true
            this.passwordData.employeeId = item.employeeId
            this.passwordData.userId = item.userId
            this.passwordData.displayName = item.displayName
            this.passwordData.password = ''
            this.passwordForm.confirmPassword = ''
        },
        sendValidation () {
            if (this.countDownNumber < this.countDownNumber0) {
                this.$message.error('重发验证码还未到时间')
                return
            }
            axios
                .fetch('userServer', '/user/sms/password', {
                    appNo: 'console'
                })
                .then(res => {
                    this.$message.success('发送成功，请注意查收')
                    this.countDownNumber0 = res.data
                    this.countDownNumber = this.countDownNumber0 - 1
                    this.countDown()
                })
        },
        countDown () {
            setTimeout(() => {
                this.countDownTitle = '' + this.countDownNumber-- + ' 秒后重发'
                if (this.countDownNumber > 0) {
                    this.countDown()
                } else {
                    this.countDownNumber = 0
                    this.countDownNumber0 = 0
                    this.countDownTitle = '重发验证码'
                }
            }, 1000)
        },
        passwordForm () {
            this.$refs['passwordForm'].validate(valid => {
                if (!valid) {
                    return false
                }
                let path = ''
                let data = {}
                if (this.modifyType === 'personal') {
                    path = '/user/sms/password/reset'
                    data = {
                        appNo: 'console',
                        validationCode: this.passwordData.validationCode,
                        newPassword: this.passwordData.password
                    }
                } else {
                    path = '/user/password/employee'
                    data = {
                        employeeId: this.passwordData.employeeId,
                        password: this.passwordData.password
                    }
                }
                axios.fetch('userServer', path, data).then(res => {
                    this.passwordVisible = false
                    this.$message.success('操作成功')
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.error-tips {
    font-size: 12px;
    line-height: 1;
    color: #f56c6c;
}
</style>
