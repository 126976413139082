<template>
    <div class="user">
        <el-row :gutter="20">
            <el-col style="margin-bottom: 10px;">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>个人信息</span>
                        <el-button style="float: right; padding: 3px 0;" type="text" @click="showPassword(user)">修改密码</el-button>
                    </div>
                    <div>
                        <div class="avatar-container">
                            <div class="avatar">
                                <!-- <el-upload class="avatar-uploader" action="#" :before-upload="handleImagesUrlBefore" :headers="{ Authorization: token }" :show-file-list="false" :on-success="handleAvatarSuccess"> -->
                                <img src="../../../assets/images/avart.png" class="avatar" />
                                <!-- </el-upload> -->
                            </div>
                            <p>{{ user.displayName }}</p>
                            <p v-if="days" class="days">今天是您加入团队的第 <b>{{ days }}</b> 天 ，请继续加油！</p>
                        </div>
                        <ul class="user-info">
                            <!-- <li>
                账号
                <div class="user-right">
                  {{ currentUserInfo.loginName }}
                </div>
              </li>
              <li>
                角色
                <div class="user-right">

                </div>
              </li> -->
                            <li>
                                手机号
                                <div class="user-right">
                                    {{ user.mobilePhone }}
                                </div>
                            </li>
                            <li>
                                邮箱
                                <div class="user-right">
                                    {{ user.email }}
                                </div>
                            </li>
                            <!-- <li>
                公司名称
                <div class="user-right">{{ user.companyName }}</div>
              </li> -->
                            <template v-if="user.employeeGroupList && user.employeeGroupList.length > 0">
                                <li v-for="(item,index) in user.employeeGroupList" :key="index" class="dept">
                                    组织
                                    <el-tooltip :content="item.levelPathName.replace(/\|/g,'-').substr(item.levelPathName.indexOf('|') + 1)" placement="top">
                                        <div class="user-right">
                                            {{ item.levelPathName.replace(/\|/g,'-').substr(item.levelPathName.indexOf('|') + 1) }}
                                        </div>
                                    </el-tooltip>
                                </li>
                            </template>
                            <li v-if="user.oaJob && user.oaJob.jobName">
                                职位 <div class="user-right"> {{ user.oaJob.jobName }}</div>
                            </li>
                            <li>
                                职级 <div class="user-right">{{ user.jobLevel }}</div>
                            </li>
                            <li>
                                工号 <div class="user-right">{{ user.jobNumber }}</div>
                            </li>
                            <li v-if="user.joinDate">
                                入职时间 <div class="user-right">{{ user.joinDate.substr(0,11) }}</div>
                            </li>
                            <li v-if="!user.status">
                                员工状态 <div class="user-right">{{ user.status | statusFilter }}</div>
                            </li>
                            <!-- <li>
                <svg-icon icon-class="anq" /> 安全设置
                <div class="user-right">
                  <a @click="$refs.pass.dialog = true">修改密码</a>
                  <a @click="$refs.email.dialog = true">修改邮箱</a>
                </div>
              </li> -->
                        </ul>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <gb-password ref="thePassword"></gb-password>
    </div>
</template>

<script>

import gbPassword from '@/components/password'
export default {
    name: '',
    components: { gbPassword },
    filters: {
        statusFilter: function (value) {
            switch (value) {
            case 0:
                return '试用'
            case 1:
                return '在职'
            case -1:
                return '离职'
            }
        }
    },
    props: {
        user: {
            type: Object,
            default: () => {
            }
        }
    },
    data () {
        return {
            currentUserInfo: {}
        }
    },
    computed: {
        days () {
            return this.user.joinDate ? this.$moment().diff(this.$moment(this.user.joinDate), 'days') : 0
        }
    },
    created () {
        this.currentUserInfo = this.$axios.getCurrentUser()
        // console.log(this.currentUserInfo);
    },
    methods: {
        showPassword (item) {
            let thePassword = this.$refs['thePassword']
            item.displayName = item.employeeName
            thePassword.show(item)
        }
    }
}
</script>
<style lang='less' scoped>
.user {
    height: 100%;

    .el-row,
    .el-col,
    .el-card {
        height: 100%;
    }

    /deep/ .el-card {
        .el-card__body {
            overflow: auto;
        }
    }

    .avatar-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.44rem;
        text-align: center;

        .avatar {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
        }

        p {
            font-size: 0.24rem;
            font-weight: 500;
            line-height: 0.44rem;
            color: #303133;
        }

        p.days {
            font-size: 0.16rem;
            color: #909399;

            b {
                color: #df5a68;
            }
        }
    }

    .user-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 0;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            width: 100%;
            height: 0.52rem;
            // padding: 11px 0;
            font-size: 0.14rem;
            color: #909399;
            border-bottom: 1px solid #f0f3f4;
        }

        .user-right {
            flex: 1;
            padding-left: 1rem;
            overflow: hidden;
            color: #303133;
            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;

            a {
                color: #317ef3;
            }
        }
    }
}
</style>
