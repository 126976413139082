<template>
    <div class="training">
        <div class="score">
            <!-- <el-link
                v-show="show"
                class="kpi"
                type="primary"
                href="/oa/estimate"
                >进入考核>></el-link
            > -->
            <p class="season">
                <span>当季积分：<b style="font-weight: 400; color: #3595e1;">{{
                    seasonScore
                }}</b></span>
                <b class="date">{{ startMonth }} ~ {{ endMonth }}</b>
            </p>
            <div class="progress">
                <span class="tag">
                    <span>及格</span>
                    <i class="el-icon-caret-bottom"></i>
                </span>
                <el-progress
                    :percentage="(seasonScore / 120) * 100"
                    :stroke-width="24"
                    :show-text="false"
                    color="#E6A23C"
                ></el-progress>
            </div>
            <p
                v-if="60 - seasonScore > 0"
                style="padding: 0.25rem 0; font-size: 0.24rem; font-weight: 500; color: #909399;"
            >
                您距离及格线还差{{
                    (60 * 100 - seasonScore * 100) / 100
                }}分，请加倍努力！
            </p>
            <p
                v-else
                style="padding: 0.25rem 0; font-size: 0.24rem; font-weight: 500; color: #909399;"
            >
                您已及格，很棒哦！
            </p>
        </div>
        <div class="title">
            <span>{{ `${year}年培训分数分析图` }}</span>
            <el-link
                class="detail"
                :underline="false"
                href="/profile/score"
            >
                查看积分明细
            </el-link>
        </div>
        <div v-show="show" id="main" style="height: 320px;"></div>
    </div>
</template>

<script>
import echarts from 'echarts'
import moment from 'moment'
export default {
    props: {
        show: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            // colorBox: ['#AED5F3', '#5DAAE7', '#3595E1'],
            colorBox: [
                '#fff',
                '#D7EAF9',
                '#AED5F3',
                '#86BFED',
                '#5DAAE7',
                '#3595E1'
            ],
            year: '',
            seasonScore: 0,
            myChart: ''
        }
    },
    computed: {
        currentSeason () {
            return Math.ceil((new Date().getMonth() + 1) / 3)
        },
        startMonth () {
            const currentQuarter = moment().quarter() // 当前是第几季度
            const currentYear = moment().year() // 当前年
            return moment(moment(currentYear + '-01-01').toDate())
                .quarter(currentQuarter)
                .format('YYYY-MM-DD')
        },
        endMonth () {
            const currentQuarter = moment().quarter() // 当前是第几季度
            const currentYear = moment().year() // 当前年
            let endMonth = 3 * parseInt(currentQuarter) // 当季度最后一个月
            /* 对月数进行格式化 */
            if (endMonth < 10) {
                endMonth = '0' + endMonth
            } else {
                endMonth += ''
            }

            const endMonthDays = moment(
                currentYear + '-' + endMonth
            ).daysInMonth() // 末尾月天数
            const endDays = currentYear + '-' + endMonth + '-' + endMonthDays // 完整年月日整合
            return moment(endDays).format('YYYY-MM-DD') // 计算结果
        }
    },
    created () {
        this.getCurrentSeasonScore()
        this.getCurrentYearScore()
        // console.log(this.currentSeason)
    },
    mounted () {
        this.year = new Date().getFullYear()
        window.onresize = () => {
            //  根据窗口大小调整曲线大小
            this.myChart.resize()
        }
    },
    methods: {
        // 获取当季积分
        getCurrentSeasonScore () {
            this.$axios
                .fetch(
                    'learningServer',
                    '/learning/learning-score-current-season'
                )
                .then(res => {
                    this.seasonScore = res.data ? res.data.score : 0
                })
        },
        // 获取当年积分
        getCurrentYearScore () {
            this.$axios
                .fetch(
                    'learningServer',
                    '/learning/learning-score-current-year'
                )
                .then(res => {
                    this.init(res.data)
                })
        },
        init (data) {
            this.myChart = echarts.init(document.getElementById('main'))
            const option = {
                // title: {
                //     top: 45,
                //     left: 'left',
                //     text: this.year + '年培训分数分析图',
                //     textStyle: {
                //         color: '#636A6F',
                //         lineHeight:'24'
                //     }
                // },
                tooltip: {
                    // 鼠标悬浮显示对应信息
                    // position: 'top',
                    formatter: function (p) {
                        var format = echarts.format.formatTime(
                            'M月d日',
                            p.data[0]
                        )
                        return format + ': ' + p.data[1] + '分'
                    }
                },
                visualMap: {
                    min: 0,
                    max: 5,
                    type: 'piecewise',
                    orient: 'horizontal',
                    top: 45,
                    right: 10,
                    left: 'right',
                    textStyle: {
                        color: '#636A6F'
                    },
                    pieces: this.generatePieces(10, this.colorBox)
                    // pieces: [
                    //   { gt: 0,lt:5,label:'0-5分',color:'#AED5F3'},
                    //   { gte: 5,lt:10,label:'5-10分',color:'#5DAAE7'},
                    //   { gte: 10,lt: Infinity,label:'10分及以上',color:'#3595E1'},
                    // ],
                },
                legend: {},
                calendar: {
                    top: 120,
                    left: 50,
                    right: 10,
                    bottom: 30,
                    cellSize: ['30', '30'],
                    range: this.year,
                    splitLine: {
                        lineStyle: {
                            color: '#c0c4cc'
                        }
                    },
                    itemStyle: {
                        borderWidth: 0.5
                        // borderColor: 'red', //格子的边框颜色
                    },
                    dayLabel: {
                        nameMap: [
                            '周日',
                            '周一',
                            '周二',
                            '周三',
                            '周四',
                            '周五',
                            '周六'
                        ],
                        fontWeight: 400,
                        color: '#A7B6C3'
                    },
                    monthLabel: {
                        nameMap: 'cn',
                        fontWeight: 400,
                        color: '#A7B6C3'
                    },
                    yearLabel: { show: false }
                },
                series: {
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: this.getVirtulData(this.year, data)
                }
            }
            this.myChart.setOption(option)
        },
        generatePieces (maxValue, colorBox) {
            var pieces = []
            var temp = {}
            temp.lt = 0.01
            temp.gt = 0
            temp.label = '0分'
            temp.color = colorBox[0]
            pieces.push(temp)
            if (maxValue) {
                for (var i = 1; i <= 5; i++) {
                    // console.log(i);
                    // temp.label = i + '分';
                    temp = {}
                    if (i < 5) {
                        temp.lte = i
                    } else {
                        temp.lt = Infinity
                    }
                    // temp.lte = i;
                    temp.gt = i - 1 + 0.01
                    temp.color = colorBox[i]
                    // temp.label = '等级'+i;
                    pieces.push(temp)
                }
            }
            pieces = pieces
                .map((item, index) => {
                    if (index === 5) {
                        item.label = index + '分及以上'
                    } else {
                        item.label = index + '分'
                    }
                    return item
                })
                .splice(1)
            // console.log(pieces)
            return pieces
        },
        getVirtulData (year, resData) {
            year = year || '2021'
            var date = +echarts.number.parseDate(year + '-01-01')
            var end = +echarts.number.parseDate(year + '-12-31')
            var dayTime = 3600 * 24 * 1000
            var data = []
            for (var time = date; time <= end; time += dayTime) {
                const filter = resData.filter(
                    item =>
                        item.periodValue ===
                        echarts.format.formatTime('yyyy-MM-dd', time)
                )
                data.push([
                    echarts.format.formatTime('yyyy-MM-dd', time),
                    filter.length > 0 ? filter[0].score : 0
                ])
            }
            return data
        }
    }
}
</script>
<style lang="less" scoped>
.training {
    position: relative;
    height: 100%;
    padding-left: 40px;

    .score {
        .kpi {
            position: absolute;
            right: 10px;
        }
        // padding: 0.1rem;
        width: 800px;
        height: 320px;

        p.season {
            display: flex;
            flex-direction: column;
            padding: 64px 0;
            font-size: 18px;
            font-weight: 400;
            color: #606266;

            .date {
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                color: #909399;
                // letter-spacing: .05em;
            }
        }
    }

    .progress {
        position: relative;

        .tag {
            position: absolute;
            top: -44px;
            left: 50%;
            display: flex;
            flex-direction: column;
            font-size: 18px;
            color: #606266;
            text-align: center;
            transform: translateX(-50%);

            span {
                font-weight: 500;
            }
        }
    }

    .title {
        position: relative;
        top: 69px;
        left: 16px;
        z-index: 2;

        > span {
            font-size: 18px;
            font-weight: 500;
            vertical-align: baseline;
        }

        .detail {
            margin-left: 15px;
            font-size: 16px;
            font-weight: 400;
            color: #3595e1;
            vertical-align: baseline;
            cursor: pointer;
        }
    }
}
</style>
