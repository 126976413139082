<template>
    <div class="center">
        <el-row :gutter="20">
            <el-col :span="6" :xs="24">
                <user-card :user="user" />
            </el-col>
            <el-col :span="18" :xs="24">
                <el-card>
                    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
                        <el-tab-pane label="我的培训" name="train">
                            <train />
                        </el-tab-pane>
                        <el-tab-pane label="我的考核" name="KPI">
                            <!-- <el-link class="kpi-link">进入考核>></el-link> -->
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import train from './component/Training'
import UserCard from './component/user'
export default {
    name: 'Center',
    components: { train, UserCard },
    data () {
        return {
            activeTab: 'train',
            user: {
                displayName: '',
                companyName: '',
                oaJob: {
                    jobName: ''
                },
                jobNumber: '',
                jobLevel: '',
                employeeGroupList: []
            }
        }
    },
    created () {
        this.getUserInfo()
    },
    methods: {
        getUserInfo () {
            this.$axios.fetch('oaServer', '/oa/employee-info-ehr').then(res => {
                if (res.data) {
                    this.user = res.data
                }
            })
        },
        handleTabClick (val) {
            console.log(val)
            if (val.label === '我的考核') {
                this.$router.push('/oa/estimate')
            }
        }
    }
}
</script>
<style lang='less' scoped>
.center {
    padding: 15px;

    .el-row,
    .el-col,
    .el-card {
        height: 100%;
    }
}
</style>
<style lang='less'>
.center {
    .el-card {
        display: flex;
        flex-direction: column;

        .el-card__body {
            height: 100%;

            .el-tabs {
                display: flex;
                flex-direction: column;
                height: 100%;

                .el-tabs__content {
                    flex: 1;
                    overflow-y: auto;
                }

                .el-tab-pane {
                    height: 100%;
                }
            }

            // .kpi-link {
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            // }
        }
    }
}
</style>
